import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDRAppmJUKUwF-517Awdy_gieiLWXAXgLU",
    authDomain: "virtualgreenhouse-4ed47.firebaseapp.com",
    databaseURL: "https://virtualgreenhouse-4ed47.firebaseio.com",
    projectId: "virtualgreenhouse-4ed47",
    storageBucket: "virtualgreenhouse-4ed47.appspot.com",
    messagingSenderId: "316363685643",
    appId: "1:316363685643:web:1d840b29ef9f9b91d5ac0e",
    measurementId: "G-E7QV4FKF9C"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  //firebase.analytics();
  //firebase.firestore().settings({ timestampsInSnapshots: true })

  export default firebase;